.photos .positions {
    width: 100%;
    aspect-ratio: 6/5;
    position: relative;
    text-align: left;
}

.photos .big {
    height: 100%;
}

.photos .small {
    position: absolute;
    height: 50%;
    right: 0;
    bottom: 25%;
    border: 2px solid #f1ede1;
}

.photos .home-buttons {
    margin-top: 50px;
    text-align: center;
    display: flex;
    justify-content: space-between;
}

@media (max-width: 767px) {
    .photos .home-buttons {
        flex-direction: column;
        gap: 20px;
    }
}


.photos .rsvp-button button {
    border: 0.5px solid #3d4737;
    font-family: "Cardo";
    color: #3d4737;
    background: transparent;
    padding: 5px 50px;
}

.photos .rsvp-button button:hover {
    cursor: pointer;
    background-color: #3d473721;
}


@media (min-width: 990px) {
    .photos {
        padding-top: 70px;
    }
}