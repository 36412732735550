.container.registry {
    margin-bottom: 80px;
    padding-top: 80px;
    animation: 2s fadeIn;
}
@media (min-width: 768px) {
    .container.registry {
        padding-top: 20px;
    }
}

.registry .section {
    margin: 35px 0;
    padding-bottom: 35px;
}

.registry a {
    text-decoration: underline solid 0.5px;
    padding: 0 4px;
    color: #978f86;
}
.registry a:hover {
    color: #978f8690;
}

.registry a i {
    font-size: 12px;
    margin-left: 5px;
}

.registry .event-button {
    margin-top: 50px;
}

.registry .event-button button {
    border: 0.5px solid #3d4737;
    font-family: "Cardo";
    color: #3d4737;
    background: transparent;
    padding: 5px 50px;
}

.registry .event-button button:hover {
    cursor: pointer;
    background-color: #3d473721;
}