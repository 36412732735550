.guest {
    margin: 50px 0;
    border-top: 0.5px solid #3d473721;
}

.guest .guest-name {
    padding-top: 25px;
}

.guest input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
}

.guest .response {
    display: flex;
    margin: 25px 0;
}

.guest .response-button {
    margin-right: 5px;
    cursor: pointer;
}

.guest .response-button input[type="radio"] + label {
    padding: 8px;
    cursor: pointer;
    border: 0.5px solid #3d473721;
}

.guest .response-button input[type="radio"]:checked + label {
    background-color: #3d473721;
}

.guest .response-button input[type="radio"]:not(:checked) + label:hover {
    background-color: #3d47370b;
}

.guest .diet {
    margin-bottom: 5px;
}

.guest .diet-list {
    display: flex;
    flex-wrap: wrap;
}

.guest .diet-other {
    display: flex;
}

.guest .diet-other p {
    margin: 2px 10px 2px 2px;
}
.guest .diet-option input[type="checkbox"]{
    border-radius: 0;
}

.guest .diet-other {
    margin: 10px 0;
}
.guest .diet-option {
    display: block;
    position: relative;
    padding-left: 25px;
    margin: 10px 20px 10px 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.guest .diet-option input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.guest .diet-option .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border: 1px solid #3d473721;
}
.guest .diet-option .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
/* Show the checkmark when checked */
.guest .diet-option input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.guest .diet-option .checkmark:after {
    left: 4px;
    top: 0px;
    width: 4px;
    height: 10px;
    border: solid #3d4737;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

input[type=text] {
    border: 0.5px solid #c8c4b7;
    border-radius: 0;
    background: transparent;
    font-family: "Cormorant Garamond";
    width: 200px;
}

input[type=text]:focus, input[type=text]:focus-visible {
    outline: 1px solid #c8c4b7;
}