.container.events {
    margin-bottom: 80px;
    padding-top: 80px;
    animation: 2s fadeIn;
}
@media (min-width: 768px) {
    .container.events {
        padding-top: 20px;
    }
}

.events .events-group {
    margin-top: 40px;
}

@media (min-width: 992px) {
    .events .events-group {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
    }
    
    .events .events-group .event {
        width: 45%;
    }
}

@media (max-width: 991px) {
    .events .event {
        margin-bottom: 50px;
    }
}

.events .event .detail {
    display: flex;
    gap: 20px;
    margin-bottom: 18px;
}

.events .event .detail.top {
    gap: 30px;
}

.events .event .detail .detail-1 {
    min-width: 65px;
}

.events .event .detail.top .title p {
    margin: 0;
}

.events .event .detail.top .title h2 {
    margin-bottom: 10px;
}

.events .event .detail p {
    margin: 0;
}

.events .calendar a {
    text-decoration: underline solid 0.5px;
    padding: 0 4px;
    color: #978f86;
}
.events .calendar a:hover {
    color: #978f8690;
}
