.App {
  text-align: center;
}

h1 {
  font-weight: normal;
  margin-bottom: 10px;
}

html, body {
  background-color: #f1ede1;
  color: #3d4737;
  font-family: "Cardo";
}

p {
  font-size: 0.9em;
  letter-spacing: 0.5px;
}

.hebrew {
  font-family: "Tinos";
  font-weight: normal;
  letter-spacing: 1px;
}

.body {
  margin: auto;
}

.hidden {
  display: none;
}

.container {
  padding: 0 30px;
}


@media (min-width: 992px) {
  .container {
      min-width: 970px;
      margin: auto;
  }
}
@media (min-width: 768px) {
  .container {
      width: 690px;
      margin: auto;
  }
}


a {
  color: #3d4737;
  cursor: pointer;
  text-decoration: none;
}

@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}

input[type="text"] {
  font-size: 16px;
}