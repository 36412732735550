.login-page {
    position: absolute;
    height: -webkit-fill-available;
    width: 100%;
    text-align: center;
}

.login-container {
    position: relative;
    top: 30%;
    padding: 0 30px;
}

.login-page form {
    margin: 40px 0;
}

.login-page input {
    padding: 10px;
}

.login-page .login-submit {
    border: none;
    background: transparent;
    color: #3d4737;
    padding: 10px;
    position: absolute;
    cursor: pointer;
}

.login-page .login-submit i {
    font-size: 10pt;
}

.login-page .error {
    font-size: 9pt;
    margin-top: 40px;
}