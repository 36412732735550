.details .section {
    margin: 50px 0;
    border-top: 0.5px solid #3d473721;
    padding-top: 30px;
}

.details a, .details button {
    text-decoration: underline solid 0.5px;
    padding: 0 4px;
    color: #978f86;
}
.details button {
    background: transparent;
    border: none;
    font-family: "Cardo";
    font-size: 1em;
}
.details a:hover, .details button:hover {
    color: #978f8690;
    cursor: pointer;
}