.invite .text {
    text-align: center;
}

.invite .text p {
    padding-left: 10px;
    padding-right: 10px;
}

.invite .text .names {
    margin: 40px 0;
}
.invite .text .names h3 {
    margin: 5px 0;
}