.container.rsvp {
    margin-bottom: 80px;
    padding-top: 80px;
    animation: 2s fadeIn;
}
@media (min-width: 768px) {
    .container.rsvp {
        padding-top: 20px;
    }
}

.rsvp button {
    border: 0.5px solid #3d4737;
    border-radius: 0;
    background: transparent;
    color: #3d4737;
    font-family: "Cardo";
    padding: 5px 50px;
    font-weight: bold;
    cursor: pointer;
}

.rsvp button:hover {
    background-color: #3d473721;
}

.rsvp .form-bottom {
    padding-top: 20px;
}